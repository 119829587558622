<template>

  <div class="row">
    <div class="flex md12 xs12">

      <vac-card v-if="isExportable" :title="$t('Last 100 Impressions')">

        <Actions :export-to-excel="exportToExcel" v-if="isExportable" />

        <div class="row">
          <div class="flex xs12 md3">
            <h1 class="title-table">Total {{ totalCount }}</h1>
          </div>
        </div>

        <div class="row align--center">

          <div class="flex xs12 md4">
            <va-input
              :value="searchLicenseName"
              :placeholder="$t('Search by license name')"
              @input="searchLicense"
              class="input--border"
              removable>
              <IconMagnifier slot="prepend" />
            </va-input>
          </div>

          <div class="flex xs12 md4">
            <va-input
              :value="searchRetailerName"
              :placeholder="$t('Search by retailer name')"
              @input="searchRetailer"
              class="input--border"
              removable>
              <IconMagnifier slot="prepend" />
            </va-input>
          </div>

          <div class="flex xs12 md3 offset--md1">
            <VnSelect
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              noClear
            />
          </div>
        </div>

        <div class="row align--center">
          <div class="flex xs12 md4">
            <va-input
              :value="searchEAN"
              :placeholder="$t('Search by EAN')"
              @input="onSearchEAN"
              class="input--border"
              removable>
              <IconMagnifier slot="prepend" />
            </va-input>
          </div>

          <div class="flex xs12 md4">
            <va-input
              :value="searchMPN"
              :placeholder="$t('Search by MPN')"
              @input="onSearchMPN"
              class="input--border"
              removable>
              <IconMagnifier slot="prepend" />
            </va-input>
          </div>

        </div>

        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)">

          <template slot="referrer" slot-scope="props">
            <div :style="{}">{{ props.rowData.referrer }}</div>
          </template>

          <template slot="referrerLink" slot-scope="props">
            <a :href="props.rowData.referrer" target="_blank" rel="noreferrer" class="link-icon">
              <i class="va-icon fa fa-external-link" style="font-size: 26px;"></i>
            </a>
          </template>

        </va-data-table>

      </vac-card>

      <vac-card v-else>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)">

          <template slot="referrer" slot-scope="props">
            <div :style="{}">{{ props.rowData.referrer }}</div>
          </template>

          <template slot="referrerLink" slot-scope="props">
            <a :href="props.rowData.referrer" target="_blank" rel="noreferrer" class="link-icon">
              <i class="va-icon fa fa-external-link" style="font-size: 26px;"></i>
            </a>
          </template>

        </va-data-table>
      </vac-card>

    </div>
  </div>

</template>

<script>
  import { debounce } from 'lodash';
  import HelperExcel from '../../services/Helpers/HelperExcel';
  import API from "../../services/API/API";
  import Actions from "../actions/Actions";
  import {refactoringDate} from "@/services/Helpers/HelperTime";
  import IconMagnifier from "@/components/ui/icons/IconMagnifier.vue";

  export default {
    name: 'DataStats',

    props: {
      data: {
        type: Array,
        default: () => [],
      },
      isExportable: {
        type: Boolean,
        default: true
      }
    },

    components: {
      Actions,
      IconMagnifier,
      VnSelect: () => import('../ui/vn-select')
    },

    data() {
      return {
        totalCount: 0,
        perPageOptions: ['10', '30', '50', '100'],
        perPage: '100',
        searchLicenseName: '',
        searchRetailerName: '',
        searchEAN: '',
        searchMPN: '',
      };
    },

    computed: {
      fields() {
        return [
          {
            name: 'time',
            sortField: 'time',
            title: this.$t('Date'),
            width: '10%',
            dataClass: "--nowrap",
          },
          {
            name: 'retailerName',
            sortField: 'retailerName',
            title: this.$t('Retailer name'),
            width: '10%',
          },
          {
            name: 'licenseName',
            sortField: 'licenseName',
            title: this.$t('License name'),
            width: '10%',
          },
          {
            name: 'productName',
            sortField: 'productName',
            title: this.$t('Product name'),
            width: '10%',
          },
          {
            name: 'ean',
            sortField: 'ean',
            title: this.$t('EAN'),
            width: '10%',
          },
          {
            name: 'mpn',
            sortField: 'mpn',
            title: this.$t('MPN'),
            width: '10%',
          },
          {
            name: '__slot:referrer',
            title: this.$t('Source'),
            dataClass: 'td--small',
          },
        ];
      },
      filteredData() {
        let data = this.data;

        if (this.searchRetailerName.length) {
          const searches = this.searchRetailerName.split(',').map(search => search.trim());
          data = data.filter(item => {
            let found = false
            searches.forEach(search => found = found || item.retailerName.toLowerCase().includes(search.toLowerCase()));
            return found;
          });
        }

        if (this.searchLicenseName.length) {
          const searches = this.searchLicenseName.split(',').map(search => search.trim());
          data = data.filter(item => {
            let found = false
            searches.forEach(search => found = found || item.licenseName.toLowerCase().includes(search.toLowerCase()));
            return found;
          });
        }

        if (this.searchEAN.length) {
          const searches = this.searchEAN.split(',').map(search => search.trim());
          data = data.filter(item => {
            let found = false
            searches.forEach(search => found = found || item.ean.toLowerCase().includes(search.toLowerCase()));
            return found;
          });
        }

        if (this.searchMPN.length) {
          const searches = this.searchMPN.split(',').map(search => search.trim());
          data = data.filter(item => {
            let found = false
            searches.forEach(search => found = found || item.mpn.toLowerCase().includes(search.toLowerCase()));
            return found;
          });
        }

        this.updateTotalCount(data.length);
        return data;
      },
    },

    methods: {

      updateTotalCount(value){
        this.totalCount = value;
      },

      exportToExcel() {
        const labels = ["Date", "Country", "Retailer Name", "License Name", "Product Name", "EAN", "MPN", "Referrer Link"];
        const data = [];
        const columnWidth = [];

        this.data.forEach(info => {
          const obj = {
            "Date": refactoringDate(info.time),
            "Country": info.country,
            "Retailer Name": info.retailerName,
            "License Name": info.licenseName,
            "Product Name": info.productName,
            "EAN": info.ean,
            "MPN": info.mpn,
            "Referrer Link": info.referrer,
          };
          data.push(obj);
        });

        labels.forEach(() => {
          columnWidth.push(16);
        });

        HelperExcel.exportToExcel({}, 'Last 100 Impressions', labels, data, columnWidth);
      },

      searchLicense: debounce(function (value) {
        this.searchLicenseName = value;
      }, 400),

      searchRetailer: debounce(function (value) {
        this.searchRetailerName = value;
      }, 400),

      onSearchEAN: debounce(function (value) {
        this.searchEAN = value;
      }, 400),

      onSearchMPN: debounce(function (value) {
        this.searchMPN = value;
      }, 400),
    },

  };
</script>

<style lang="scss">

</style>
